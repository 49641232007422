exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-anna-daan-js": () => import("./../../../src/pages/anna-daan.js" /* webpackChunkName: "component---src-pages-anna-daan-js" */),
  "component---src-pages-annadaan-js": () => import("./../../../src/pages/annadaan.js" /* webpackChunkName: "component---src-pages-annadaan-js" */),
  "component---src-pages-ayodhya-annadaan-js": () => import("./../../../src/pages/ayodhya-annadaan.js" /* webpackChunkName: "component---src-pages-ayodhya-annadaan-js" */),
  "component---src-pages-ayodhya-festival-js": () => import("./../../../src/pages/ayodhya-festival.js" /* webpackChunkName: "component---src-pages-ayodhya-festival-js" */),
  "component---src-pages-become-life-patron-js": () => import("./../../../src/pages/become-life-patron.js" /* webpackChunkName: "component---src-pages-become-life-patron-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donate-akshaya-tritiya-js": () => import("./../../../src/pages/donate/akshaya-tritiya.js" /* webpackChunkName: "component---src-pages-donate-akshaya-tritiya-js" */),
  "component---src-pages-donate-dhanteras-js": () => import("./../../../src/pages/donate/dhanteras.js" /* webpackChunkName: "component---src-pages-donate-dhanteras-js" */),
  "component---src-pages-donate-gaur-purnima-js": () => import("./../../../src/pages/donate/gaur-purnima.js" /* webpackChunkName: "component---src-pages-donate-gaur-purnima-js" */),
  "component---src-pages-donate-gaur-purnima-seva-js": () => import("./../../../src/pages/donate/gaur-purnima-seva.js" /* webpackChunkName: "component---src-pages-donate-gaur-purnima-seva-js" */),
  "component---src-pages-donate-index-js": () => import("./../../../src/pages/donate/index.js" /* webpackChunkName: "component---src-pages-donate-index-js" */),
  "component---src-pages-donate-janmashtami-2024-js": () => import("./../../../src/pages/donate/janmashtami-2024.js" /* webpackChunkName: "component---src-pages-donate-janmashtami-2024-js" */),
  "component---src-pages-donate-janmashtami-js": () => import("./../../../src/pages/donate/janmashtami.js" /* webpackChunkName: "component---src-pages-donate-janmashtami-js" */),
  "component---src-pages-donate-janmashtami-seva-js": () => import("./../../../src/pages/donate/janmashtami-seva.js" /* webpackChunkName: "component---src-pages-donate-janmashtami-seva-js" */),
  "component---src-pages-donate-janmashtami-special-js": () => import("./../../../src/pages/donate/janmashtami-special.js" /* webpackChunkName: "component---src-pages-donate-janmashtami-special-js" */),
  "component---src-pages-donate-kartik-deepotsava-seva-js": () => import("./../../../src/pages/donate/kartik-deepotsava-seva.js" /* webpackChunkName: "component---src-pages-donate-kartik-deepotsava-seva-js" */),
  "component---src-pages-donate-makar-sankranti-js": () => import("./../../../src/pages/donate/makar-sankranti.js" /* webpackChunkName: "component---src-pages-donate-makar-sankranti-js" */),
  "component---src-pages-donate-rama-navami-js": () => import("./../../../src/pages/donate/rama-navami.js" /* webpackChunkName: "component---src-pages-donate-rama-navami-js" */),
  "component---src-pages-donate-sri-krishna-janmashtami-js": () => import("./../../../src/pages/donate/sri-krishna-janmashtami.js" /* webpackChunkName: "component---src-pages-donate-sri-krishna-janmashtami-js" */),
  "component---src-pages-donations-js": () => import("./../../../src/pages/donations.js" /* webpackChunkName: "component---src-pages-donations-js" */),
  "component---src-pages-eazypay-index-js": () => import("./../../../src/pages/eazypay/index.js" /* webpackChunkName: "component---src-pages-eazypay-index-js" */),
  "component---src-pages-eazypay-status-js": () => import("./../../../src/pages/eazypay/status.js" /* webpackChunkName: "component---src-pages-eazypay-status-js" */),
  "component---src-pages-ekadasi-index-js": () => import("./../../../src/pages/ekadasi/index.js" /* webpackChunkName: "component---src-pages-ekadasi-index-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-free-puja-js": () => import("./../../../src/pages/free-puja.js" /* webpackChunkName: "component---src-pages-free-puja-js" */),
  "component---src-pages-gita-daan-js": () => import("./../../../src/pages/gita-daan.js" /* webpackChunkName: "component---src-pages-gita-daan-js" */),
  "component---src-pages-gita-olympiad-index-js": () => import("./../../../src/pages/gita-olympiad/index.js" /* webpackChunkName: "component---src-pages-gita-olympiad-index-js" */),
  "component---src-pages-gita-olympiad-marathi-js": () => import("./../../../src/pages/gita-olympiad/marathi.js" /* webpackChunkName: "component---src-pages-gita-olympiad-marathi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pan-js": () => import("./../../../src/pages/pan.js" /* webpackChunkName: "component---src-pages-pan-js" */),
  "component---src-pages-pay-js": () => import("./../../../src/pages/pay.js" /* webpackChunkName: "component---src-pages-pay-js" */),
  "component---src-pages-payment-index-js": () => import("./../../../src/pages/payment/index.js" /* webpackChunkName: "component---src-pages-payment-index-js" */),
  "component---src-pages-payment-status-js": () => import("./../../../src/pages/payment/status.js" /* webpackChunkName: "component---src-pages-payment-status-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-programs-bhagavad-gita-online-sessions-js": () => import("./../../../src/pages/programs/bhagavad-gita-online-sessions.js" /* webpackChunkName: "component---src-pages-programs-bhagavad-gita-online-sessions-js" */),
  "component---src-pages-programs-daily-bhagavad-gita-js": () => import("./../../../src/pages/programs/daily-bhagavad-gita.js" /* webpackChunkName: "component---src-pages-programs-daily-bhagavad-gita-js" */),
  "component---src-pages-programs-folk-friends-of-lord-krishna-js": () => import("./../../../src/pages/programs/folk-friends-of-lord-krishna.js" /* webpackChunkName: "component---src-pages-programs-folk-friends-of-lord-krishna-js" */),
  "component---src-pages-programs-free-bhagavad-gita-js": () => import("./../../../src/pages/programs/free-bhagavad-gita.js" /* webpackChunkName: "component---src-pages-programs-free-bhagavad-gita-js" */),
  "component---src-pages-programs-gita-life-js": () => import("./../../../src/pages/programs/gita-life.js" /* webpackChunkName: "component---src-pages-programs-gita-life-js" */),
  "component---src-pages-programs-gita-sessions-js": () => import("./../../../src/pages/programs/gita-sessions.js" /* webpackChunkName: "component---src-pages-programs-gita-sessions-js" */),
  "component---src-pages-programs-icvk-indian-culture-and-values-for-kids-js": () => import("./../../../src/pages/programs/icvk-indian-culture-and-values-for-kids.js" /* webpackChunkName: "component---src-pages-programs-icvk-indian-culture-and-values-for-kids-js" */),
  "component---src-pages-programs-icvk-registration-js": () => import("./../../../src/pages/programs/icvk-registration.js" /* webpackChunkName: "component---src-pages-programs-icvk-registration-js" */),
  "component---src-pages-programs-index-js": () => import("./../../../src/pages/programs/index.js" /* webpackChunkName: "component---src-pages-programs-index-js" */),
  "component---src-pages-programs-learn-bhagavad-gita-js": () => import("./../../../src/pages/programs/learn-bhagavad-gita.js" /* webpackChunkName: "component---src-pages-programs-learn-bhagavad-gita-js" */),
  "component---src-pages-programs-vrjp-gita-sessions-js": () => import("./../../../src/pages/programs/vrjp-gita-sessions.js" /* webpackChunkName: "component---src-pages-programs-vrjp-gita-sessions-js" */),
  "component---src-pages-quiz-bg-questions-js": () => import("./../../../src/pages/quiz/bg-questions.js" /* webpackChunkName: "component---src-pages-quiz-bg-questions-js" */),
  "component---src-pages-quiz-bg-result-js": () => import("./../../../src/pages/quiz/bg-result.js" /* webpackChunkName: "component---src-pages-quiz-bg-result-js" */),
  "component---src-pages-quiz-bhagavad-gita-js": () => import("./../../../src/pages/quiz/bhagavad-gita.js" /* webpackChunkName: "component---src-pages-quiz-bhagavad-gita-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-seva-js": () => import("./../../../src/pages/seva.js" /* webpackChunkName: "component---src-pages-seva-js" */),
  "component---src-pages-sp-ashraya-form-index-js": () => import("./../../../src/pages/sp-ashraya-form/index.js" /* webpackChunkName: "component---src-pages-sp-ashraya-form-index-js" */),
  "component---src-pages-srila-prabhupada-ashraya-js": () => import("./../../../src/pages/srila-prabhupada-ashraya.js" /* webpackChunkName: "component---src-pages-srila-prabhupada-ashraya-js" */),
  "component---src-pages-srila-prabhupada-js": () => import("./../../../src/pages/srila-prabhupada.js" /* webpackChunkName: "component---src-pages-srila-prabhupada-js" */),
  "component---src-pages-srimad-bhagavatam-js": () => import("./../../../src/pages/srimad-bhagavatam.js" /* webpackChunkName: "component---src-pages-srimad-bhagavatam-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-yatras-ayodhya-js": () => import("./../../../src/pages/yatras/ayodhya.js" /* webpackChunkName: "component---src-pages-yatras-ayodhya-js" */),
  "component---src-pages-yatras-index-js": () => import("./../../../src/pages/yatras/index.js" /* webpackChunkName: "component---src-pages-yatras-index-js" */),
  "component---src-templates-blog-detail-js": () => import("./../../../src/templates/BlogDetail.js" /* webpackChunkName: "component---src-templates-blog-detail-js" */),
  "component---src-templates-seva-js": () => import("./../../../src/templates/Seva.js" /* webpackChunkName: "component---src-templates-seva-js" */)
}

